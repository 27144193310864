<template>
  <div>
    <!-- 顶部head区域 -->
    <transition name="slide">
      <div class="menuDiv" v-if="show">
        <button @click="closeMenuClick" class="closeBtn"></button>
        <span></span>
        <div class="menu">
          <div>首 &emsp;&emsp;页</div>
          <div>
            <router-link to="/CompanyProfile">公司简介</router-link>
          </div>
          <div>
            <router-link to="/system">系统优势</router-link>
          </div>
          <div>
            <router-link to="/changyeyuan">产业园区</router-link>
          </div>
          <div>
            <router-link to="/cooperation">精诚合作</router-link>
          </div>
          <div>
            <router-link to="/news">新闻资讯</router-link>
          </div>
          <div>
            <router-link to="/TalentRecruitment">人才招聘</router-link>
          </div>
          <div>
            <router-link to="/about">关于我们</router-link>
          </div>
        </div>
      </div>
    </transition>
    <div class="headDiv">
      <button @click="showMenuClick" class="menuBtn" v-if="showBtn"></button>
      <img src="../assets/home_slices/logo@2x.png" alt="" />
      <button class="moreBtn" @click="topRedMore">Read More</button>
    </div>
    <!-- 板块区域 -->
    <div class="numb">
      <div class="numb_head">
        <h6>Leading the industry with data</h6>
        <span class="father">用<span class="son">数据</span>领跑行业</span>
        <h5 class="numb_head_h5">实时数据</h5>
      </div>
      <div class="contentBox">
        <div>
          <img src="../assets/home_slices/zu30@2x.png" alt="" class="bgImage" />
          <div class="numbBox_imgtxt">数字存证版块</div>
          <div class="numbBox_left" @click="redMore1">
            <img src="../assets/home_slices/hp-icon-5@2x.png" alt="" />
            <h1>
              <animate-number
                from="0"
                :to="Data.bankCount || 0"
                :key="Data.bankCount"
                duration="2000"
                :formatter="formatter"
              >
              </animate-number>
            </h1>
            <h5>入驻银行/家</h5>
            <button>Read More</button>
          </div>
          <div class="numbBox_right" @click="redMore2">
            <img src="../assets/home_slices/law31@2x.png" alt="" />
            <h1>
              <animate-number
                ref="animateNumber"
                mode="manual"
                :animate-end="accumulateEnd"
                :from="signNumbEnd"
                :to="Data.signCount || 0"
                :key="Data.signCount"
                duration="2000"
                :formatter="formatter"
              ></animate-number>
            </h1>
            <h5>数字存证数量/份</h5>
            <button>Read More</button>
          </div>
        </div>
        <div>
          <img src="../assets/home_slices/zu30@2x.png" alt="" class="bgImage" />
          <div class="numbBox_imgtxt">互联网法诉版块</div>
          <div class="numbBox_left" @click="redMore3">
            <img src="../assets/home_slices/hp-icon-5@2x.png" alt="" />
            <h1>
              <animate-number
                from="0"
                :to="float_mul(Number(Data.litigationCount), 1) || 0"
                :key="float_mul(Number(Data.litigationCount), 1)"
                duration="2000"
                :formatter="formatter"
              >
              </animate-number>
            </h1>
            <h5>法诉案件数量/件 </h5>
            <button>Read More</button>
          </div>
          <div class="numbBox_right" @click="redMore4">
            <img src="../assets/home_slices/law32.png" alt="" />
            <h1>
              <animate-number
                from="0"
               :to="amount_processing(
                          float_mul(Number(Data.litigationSum), 1)
                        ) || 0"
                :key="amount_processing(
                          float_mul(Number(Data.litigationSum), 1)
                        )"
                duration="2000"
                :formatter="formatter2"
              >
              </animate-number>
            </h1>
            <h5>法诉案件金额/万元</h5>
            <button>Read More</button>
          </div>
        </div>
        <div>
          <img src="../assets/home_slices/zu30@2x.png" alt="" class="bgImage" />
          <div class="numbBox_imgtxt">人民调解版块</div>
          <div class="numbBox_left" @click="redMore5">
            <img src="../assets/home_slices/hp-icon-3@2x.png" alt="" />
            <h1>
              <animate-number
                from="0"
                :to="float_add(Number(Data.litigationCount), Number(Data.mediateCount)) || 0"
               :key="float_add(Number(Data.litigationCount), Number(Data.mediateCount))"
                duration="2000"
                :formatter="formatter"
              >
              </animate-number>
            </h1>
            <h5>人民调解数量/件</h5>
            <button>Read More</button>
          </div>
          <div class="numbBox_right" @click="redMore6">
            <img src="../assets/home_slices/hp-icon-4@2x.png" alt="" />
            <h1>
              <animate-number
                from="0"
                :to="(float_add(Number(Data.mediateSum), Number(Data.litigationSum)) || 0) / 10000"
                :key="float_add(Number(Data.mediateSum), Number(Data.litigationSum))"
                duration="2000"
                :formatter="formatter2"
              >
              </animate-number>
            </h1>
            <h5>人民调解金额/万元</h5>
            <button>Read More</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 产业园区域 -->
    <div class="changyeyuan">
      <div class="zhanweiDiv"></div>
      <div
        class="ahBtn"
        @click="cyyIsShow = 1"
        :class="{ select: cyyIsShow == 1 }"
      >
        <div class="img1" :class="{ selectImg: cyyIsShow == 1 }"></div>
        <p class="p1">安徽望江法正产业园</p>
        <p class="p2">AN HUI WANG JIANG Forensic Industry Park</p>
        <img src="../assets/home_slices/tc17@2x.png" alt="" class="img2" />
      </div>
      <div class="ahContent" v-show="cyyIsShow == 1">
        <div class="contentHead">
          <div>招商&emsp;&nbsp;丨</div>
          <div>资管&emsp;&nbsp;丨</div>
          <div>律师&emsp;&nbsp;丨</div>
          <div>调解&emsp;&nbsp;</div>
        </div>
        <img
          src="../assets/home_slices/WechatIMG147.png"
          alt=""
          class="contentImg"
        />
        <p class="contentP">
          安徽望江法正产业园与政府合作成立存证产业平台-我司与政府合作成立存证产业平台，为入驻该产业园的持牌金融机构提供在线客户交易场景电子合同、实名认证、交易凭证等关键数据合法性的存证服务，在当地法院完成批量化、信息化、自动化的司法诉讼，解决沉积已久的金融纠纷，化解金融矛盾，为促进互联网金融诚信体系的建设发挥积极作用。
        </p>
        <div class="moreBtn" @click="pushChangyeyuan(cyyIsShow)">
          Read More >>>
        </div>
      </div>
      <div
        class="scBtn"
        @click="cyyIsShow = 2"
        :class="{ select: cyyIsShow == 2 }"
      >
        <div class="img1" :class="{ selectImg: cyyIsShow == 2 }"></div>
        <p class="p1">四川名山法正产业园</p>
        <p class="p2">SI CHUAN MING SHAN Forensic Industry Park</p>
        <img src="../assets/home_slices/tc17@2x.png" alt="" class="img2" />
      </div>
      <div class="scContent" v-show="cyyIsShow == 2">
        <div class="contentHead">
          <div>招商&emsp;&nbsp;丨</div>
          <div>资管&emsp;&nbsp;丨</div>
          <div>律师&emsp;&nbsp;丨</div>
          <div>调解&emsp;&nbsp;</div>
        </div>
        <img
          src="../assets/home_slices/WechatIMG146.png"
          alt=""
          class="contentImg"
        />
        <p class="contentP">
          四川名山法正产业园与政府合作成立存证产业平台-我司与政府合作成立存证产业平台，为入驻该产业园的持牌金融机构提供在线客户交易场景电子合同、实名认证、交易凭证等关键数据合法性的存证服务，在当地法院完成批量化、信息化、自动化的司法诉讼，解决沉积已久的金融纠纷，化解金融矛盾，为促进互联网金融诚信体系的建设发挥积极作用。
        </p>
        <div class="moreBtn" @click="pushChangyeyuan(cyyIsShow)">
          Read More >>>
        </div>
      </div>

      <div
        class="scBtn"
        @click="cyyIsShow = 5"
        :class="{ select: cyyIsShow == 5 }"
      >
        <div class="img1" :class="{ selectImg: cyyIsShow == 5 }"></div>
        <p class="p1">成都成互法正产业园</p>
        <p class="p2">CHENG DU cheng hu Forensic Industry Park</p>
        <img src="../assets/home_slices/tc17@2x.png" alt="" class="img2" />
      </div>
      <div class="scContent" v-show="cyyIsShow == 5">
        <div class="contentHead">
          <div>招商&emsp;&nbsp;丨</div>
          <div>资管&emsp;&nbsp;丨</div>
          <div>律师&emsp;&nbsp;丨</div>
          <div>调解&emsp;&nbsp;</div>
        </div>
        <img src="../assets/home_slices/ch.png" alt="" class="contentImg" />
        <p class="contentP">
          成都成互法正产业园与政府合作成立存证产业平台-我司与政府合作成立存证产业平台，为入驻该产业园的持牌金融机构提供在线客户交易场景电子合同、实名认证、交易凭证等关键数据合法性的存证服务，在当地法院完成批量化、信息化、自动化的司法诉讼，解决沉积已久的金融纠纷，化解金融矛盾，为促进互联网金融诚信体系的建设发挥积极作用。
        </p>
        <div class="moreBtn" @click="pushChangyeyuan(cyyIsShow)">
          Read More >>>
        </div>
      </div>
      <div
        class="gdBtn"
        @click="cyyIsShow = 4"
        :class="{ select: cyyIsShow == 4 }"
      >
        <div class="img1" :class="{ selectImg: cyyIsShow == 4 }"></div>
        <p class="p1">湖南衡南法正产业园</p>
        <p class="p2">HU NAN heng nan Forensic Industry Park</p>
        <img src="../assets/home_slices/tc17@2x.png" alt="" class="img2" />
      </div>
      <div class="gdContent" v-show="cyyIsShow == 4">
        <div class="contentHead">
          <div>招商&emsp;&nbsp;丨</div>
          <div>资管&emsp;&nbsp;丨</div>
          <div>律师&emsp;&nbsp;丨</div>
          <div>调解&emsp;&nbsp;</div>
        </div>
        <img src="../assets/home_slices/hn.png" alt="" class="contentImg" />
        <p class="contentP">
          湖南衡南法正产业园与政府合作成立存证产业平台，为入驻该产业园的持牌金融机构提供在线客户交易场景电子合同、实名认证、交易凭证等关键数据合法性的存证服务，在当地法院完成批量化、信息化、自动化的司法诉讼，解决沉积已久的金融纠纷，化解金融矛盾，为促进互联网金融诚信体系的建设发挥积极作用。
        </p>
        <div class="moreBtn" @click="pushChangyeyuan(cyyIsShow)">
          Read More >>>
        </div>
      </div>

      <div
        class="gdBtn"
        @click="cyyIsShow = 3"
        :class="{ select: cyyIsShow == 3 }"
      >
        <div class="img1" :class="{ selectImg: cyyIsShow == 3 }"></div>
        <p class="p1">广东汕头法正产业园</p>
        <p class="p2">GUANG DONG shan tou Forensic Industry Park</p>
        <img src="../assets/home_slices/tc17@2x.png" alt="" class="img2" />
      </div>
      <div class="gdContent" v-show="cyyIsShow == 3">
        <div class="contentHead">
          <div>招商&emsp;&nbsp;丨</div>
          <div>资管&emsp;&nbsp;丨</div>
          <div>律师&emsp;&nbsp;丨</div>
          <div>调解&emsp;&nbsp;</div>
        </div>
        <img
          src="../assets/home_slices/2764@2x.png"
          alt=""
          class="contentImg"
        />
        <p class="contentP">
          广东汕头法正产业园与政府合作成立存证产业平台，为入驻该产业园的持牌金融机构提供在线客户交易场景电子合同、实名认证、交易凭证等关键数据合法性的存证服务，在当地法院完成批量化、信息化、自动化的司法诉讼，解决沉积已久的金融纠纷，化解金融矛盾，为促进互联网金融诚信体系的建设发挥积极作用。
        </p>
        <div class="moreBtn" @click="pushChangyeyuan(cyyIsShow)">
          Read More >>>
        </div>
      </div>
    </div>
    <!-- 适用场景 -->
    <div class="scene">
      <div class="scene_titleEnglish">APPLICABLE SCENARIOS</div>
      <div class="scene_title">适用场景</div>
      <div class="scene_content">
        <img src="../assets/home_slices/fy@2x.png" alt="" />
        <img src="../assets/home_slices/ls@2x.png" alt="" />
        <img src="../assets/home_slices/bank@2x.png" alt="" />
        <img src="../assets/home_slices/xiaodai@2x.png" alt="" />
        <img src="../assets/home_slices/bx@2x.png" alt="" />
        <img src="../assets/home_slices/xfjr@2x.png" alt="" />
        <img src="../assets/home_slices/rzzl@2x.png" alt="" />
        <img src="../assets/home_slices/zggs@2x.png" alt="" />
      </div>
    </div>
    <!-- 融调宝 -->
    <div class="rongdiaobao">
      <div class="numb_head seil">
        <h6>COOPERATION</h6>
        <span class="father">战略<span class="son">合作</span></span>
      </div>
      <div class="title_text text_2em" style="padding: 20px">
        金科玉律本着“资源共享、互利互惠”的合作原则，与优质合作方达成战略合作，金科玉律将竭尽全力为战略合作单位提供周到完善的服务，共享资源和技术，实现优势互补，从而更好地满足市场需求
      </div>
      <div class="swipe_body">
        <Swipe :loop="false" :width="170">
          <SwipeItem class="swipe_item_x">
            <div class="swipe_item_is_box">
              <div class="swipe_item_is">
                <van-image
                  class="img"
                  fit="cover"
                  width="90px"
                  height="90px"
                  :src="require('../assets/jdy.png')"
                />
                <p>金斗云律师事务所</p>
                <div class="text text_2em">
                  金斗云律师事务所针对互联网金融、消费金融纷高度标准化、类型化、要素化及纯线上化的特点，开发案件相应管理系统，以解决互联网金融的一些问题。
                </div>
              </div>
            </div>
          </SwipeItem>
          <SwipeItem class="swipe_item_x">
            <div class="swipe_item_is_box">
              <div class="swipe_item_is">
                <van-image
                  class="img"
                  fit="cover"
                  width="90px"
                  height="90px"
                  :src="require('../assets/hy.png')"
                />
                <p>北京华宇信息技术</p>
                <div class="text text_2em">
                  华宇信息利用大数据、人工智能等前沿技术，整合先进的产品、解决方案及服务，构建起覆盖法律相关行业和法律服务领域以及高校、科研院所等机构的法律服务生态，用新一代的法律科技推动新时代的法律服务。
                </div>
              </div>
            </div>
          </SwipeItem>
          <SwipeItem class="swipe_item_x">
            <div class="swipe_item_is_box">
              <div class="swipe_item_is">
                <van-image
                  class="img"
                  fit="cover"
                  width="90px"
                  height="90px"
                  :src="require('../assets/tdh.png')"
                />
                <p>通达海</p>
                <div class="text text_2em">
                  南京通达海科技股份有限公司专业从事司法信息系统研发、信息技术服务的高科技公司。拥有以“智慧审判”、“智慧执行”、“智慧诉服”、“智慧管理”为代表的智慧法院整体解决方案和百余项自主软件产品。
                </div>
              </div>
            </div>
          </SwipeItem>
        </Swipe>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="news">
      <span class="news_line1"></span>
      <span class="news_title"
        ><span class="news_title_son">新闻</span>资讯</span
      >
      <span class="news_line2"></span>
      <div
        class="news_content"
        v-for="(item, index) in this.newsData"
        :key="index"
      >
        <div class="news_content_box" @click="pushNewsDetail(item.noticeId)">
          <div class="news_data">
            {{
              item.creatorTime.split(" ")[0].split("-")[1] +
              "-" +
              item.creatorTime.split(" ")[0].split("-")[2]
            }}
          </div>
          <div class="news_years">
            {{ item.creatorTime.split(" ")[0].split("-")[0] }}
          </div>
          <div class="news_line3"></div>
          <div class="news_contentTitle">{{ item.noticeName }}</div>
          <div class="news_content_content">{{ item.noticeIntro }}</div>
        </div>
      </div>
    </div>
    <!-- 底部区域 -->
    <div class="bottom">
      <div class="bottom_line"></div>
      <div class="bottom_head">
        <div>产业园</div>
        <div>业务对接</div>
        <div>关于我们</div>
      </div>
      <div class="bottom_phone">
        <img src="../assets/home_slices/phone@2x.png" alt="" />
        <div>028-65770918</div>
      </div>
      <div class="bottom_location">
        <img src="../assets/home_slices/loction@2x.png" alt="" />
        <div>中国四川省成都市高新区交子大道中海国际中心D座1802</div>
      </div>
      <div class="qrcode">
        <div>
          <img src="../assets/home_slices/qrcode@2x.png" alt="" />
          <p>关注微信公众号</p>
        </div>
        <div>
          <img src="../assets/home_slices/8f3abd46@2x.png" alt="" />
          <p>企业微信</p>
        </div>
      </div>
      <div class="bottom_line2"></div>
      <p class="bottom_beianhao">备案号：蜀ICP备2020025845号</p>
      <p class="bottom_beianhao2">成都金科玉律科技有限公司提供技术支持</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import filter from "@/Utils/filter";
import { Swipe, SwipeItem, Button, Image as VanImage } from "vant";

export default {
  name: "App",
  components: {
    // changeKeyWord
  },
  data() {
    return {
      show: false,
      cyyIsShow: "1",
      showBtn: true,
      Data: {
        mediateSum: 0,
      },
      newsData: [],
      // 定时器参数
      timer: "",
      value: 0,
      // 数字存证数量
      signNumbEnd: 0,
    };
  },
  components: {
    Swipe,
    Button,
    SwipeItem,
    VanImage,
  },
  mounted() {
    this.getData();
    this.getNewsData();
    this.timer = setInterval(this.getData, 5000);
  },
  methods: {
    float_add(arg1, arg2) {
      if (!(typeof (arg1) === 'number') && (arg1 !== Infinity) && !isNaN(arg1)) {
        return 0
      }
      if (!(typeof (arg2) === 'number') && (arg2 !== Infinity) && !isNaN(arg2)) {
        return 0
      }
      let r1, r2, m
      try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
      try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2))
      return (arg1 * m + arg2 * m) / m
    },
    amount_processing(val) {
      var str = (val / 10000).toFixed(2) + "";
      var intSum = str.substring(0, str.indexOf(".")); // 取到整数部分

      var dot = str.substring(str.length, str.indexOf(".")); // 取到小数部分搜索
      var ret = intSum + dot;
      console.log(ret, "retretret");
      console.log(Number(ret), "ret");
      return Number(ret);
    },
     float_mul(arg1, arg2) {
    if (!(typeof (arg1) === 'number') && (arg1 !== Infinity) && !isNaN(arg1)) {
      return 0
    }
    if (!(typeof (arg2) === 'number') && (arg2 !== Infinity) && !isNaN(arg2)) {
      return 0
    }
    // eslint-disable-next-line one-var
    let m = 0, s1 = arg1.toString(), s2 = arg2.toString()
    try { m += s1.split('.')[1].length } catch (e) {}
    try { m += s2.split('.')[1].length } catch (e) {}
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
  },
    formatter: function (e) {
      return filter.nums(parseInt(e));
    },
    formatter2: function (e) {
      return filter.nums(e);
    },
    accumulateEnd(end) {
      console.log("signCount-----", this.Data.signCount);
      this.signNumbEnd = this.Data.signCount;
      console.log("accumulateEnd-----", this.signNumbEnd);
    },
    showMenuClick() {
      this.showBtn = !this.showBtn;
      this.show = !this.show;
    },
    closeMenuClick() {
      this.showBtn = !this.showBtn;
      this.show = !this.show;
    },
    topRedMore() {
      this.$router.push("/CompanyProfile");
    },
    // redMore1-6
    redMore1() {
      this.$router.push("/cooperation");
    },
    redMore2() {
      this.$router.push("/numberDetail");
    },
    redMore3() {
      this.$router.push("/cooperation");
    },
    redMore4() {
      this.$router.push("/cooperation");
    },
    redMore5() {
      window.open("https://www.wjhyrmtj.com/#/", "_blank");
    },
    redMore6() {
      window.open("https://www.wjhyrmtj.com/#/", "_blank");
    },
    pushChangyeyuan(cyyId) {
      console.log("cyyId=====", cyyId);
      this.$router.push({
        name: "changyeyuan",
        params: { changyeyuanType: cyyId },
      });
    },
    pushNewsDetail(newsId) {
      console.log("newsID111111=====", newsId);
      this.$router.push({
        name: "newsDetail",
        params: { newsId: newsId },
      });
    },
    getData() {
      axios
        .get("https://plapi.cdjkyl.com/plaintiff/api/home/statisticsCase", {
          headers: { Authorization: "Authorization" },
        })
        .then((res) => {
          console.log(res.data);
          this.Data = res.data.data;
          // console.log("Data========" + this.Data);

          if (this.signNumbEnd == 0) {
            // 首次赋值，启动动画start()
            setTimeout(() => {
              this.$refs.animateNumber.start();
            }, 300);
          } else {
            // 第二次更新值，先reset()，再执行启动动画start()
            setTimeout(() => {
              this.$refs.animateNumber.reset(
                this.signNumbEnd,
                this.Data.signCount
              );
              this.$refs.animateNumber.start();
            }, 300);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getNewsData() {
      axios
        .post(
          "https://plapi.cdjkyl.com/plaintiff/api/home/getNews",
          { limit: 4, page: 1, noticeType: "", orgCode: "J001" },
          {
            headers: { Authorization: "Authorization" },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.newsData = res.data.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss">
.swipe_item_is {
  text-align: center;

  p {
    text-align: center;
  }
  .img {
    margin: 0 auto;
  }
}
.swipe_body {
  padding: 0 20px;
  .van-swipe__indicators {
    display: none;
  }
}
.swipe_item_x {
}
.swipe_item_is_box {
  padding: 10px;
}
.swipe_item_is {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 10px 5px rgba(6, 0, 1, 0.1);
  padding-top: 24px;
  height: 500px;
  .img {
    margin-bottom: 14px;
  }
  .text {
    font-size: 15px;
    text-align: left;
    line-height: 1.5;
    padding: 0 14px;
    margin-top: 25px;
  }
}
.swipe_item_is::after {
  background: #c59d49;
  content: "";
  display: block;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 4px;
}
.coo_body {
  text-align: center;
  margin: 42px 17px;
  .title_text {
    margin-top: 25px;
    text-align: left;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 30px;
  }
}
.text_2em {
  text-indent: 2em;
}
</style>
<style scoped>
.seil {
  position: relative !important;
}
.headDiv {
  position: relative;
  width: 100%;
  height: 292px;
  background-image: url(../assets/home_slices/WechatIMG142.png);
  background-size: cover;
}

.headDiv .moreBtn {
  position: absolute;
  top: 190px;
  width: 30%;
  left: 35%;
  height: 30px;
  border: 0.5px solid #f0ab06;
  border-radius: 2px;
  color: white;
  font-size: 12px;
  background-color: unset;
}

/* 板块区域 */
.numb {
  position: relative;
  width: 100%;
  height: 750px;
  background-color: white;
}

/* numb头部区域 */
.numb_head {
  position: absolute;
  top: 0;
  height: 100px;
  width: 100%;
}

.numb_head h6 {
  position: absolute;
  top: 40px;
  width: 100%;
  text-align: center;
  color: #ababab;
  font-size: 10px;
}

.numb_head .father {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #000000;
  font-weight: 700;
}

.numb_head .son {
  color: #ffb70c;
}
.numb_head .numb_head_h5 {
  position: absolute;
  top: 95px;
  left: 50%;
  font-size: 10px;
  font-weight: 400;
  color: #868686;
  transform: translate(-50%);
}

.numb h5 {
  position: absolute;
  top: 95px;
  left: 40%;
  font-size: 10px;
  font-weight: 400;
  color: #868686;
}

/* numb 数据区域 */
.contentBox {
  display: fixed;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 130px;
  left: 5%;
  width: 90%;
  height: 600px;
}

.contentBox div {
  position: relative;
  width: 100%;
  height: 190px;
  margin-bottom: 10px;
}

.bgImage {
  width: 100%;
}

.contentBox .numbBox_imgtxt {
  position: absolute;
  top: 6px;
  font-size: 15px;
  color: white;
  width: 100%;
  text-align: center;
}

.contentBox .numbBox_left {
  position: absolute;
  left: 0;
  top: 45px;
  width: 50%;
  height: 135px;
  margin-bottom: 0px;
}

.contentBox .numbBox_right {
  position: absolute;
  right: 0;
  top: 45px;
  width: 50%;
  height: 135px;
  margin-bottom: 0px;
}

.numbBox_left img,
.numbBox_right img {
  position: absolute;
  top: 10px;
  width: 30px;
  height: 30px;
  left: 50%;
  transform: translate(-50%);
}

.numbBox_left h1,
.numbBox_right h1 {
  position: absolute;
  top: 50px;
  width: 100%;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
}

.numbBox_left h5,
.numbBox_right h5 {
  position: absolute;
  top: 82px;
  left: 0;
  width: 100%;
  /* font-size: 10px; */
  text-align: center;
  font-weight: 400;
}

.numbBox_left button,
.numbBox_right button {
  position: absolute;
  top: 108px;
  left: 30%;
  width: 40%;
  height: 20px;
  text-align: center;
  border: 0.5px solid #ffb70c;
  color: #ffb70c;
  font-size: 10px;
  background-color: unset;
}

/* 产业园区域 */
.changyeyuan {
  background-image: url(../assets/home_slices/jx3@2x.png);
  background-size: 100%;
  width: 100%;
  height: 746px;
}

.zhanweiDiv {
  width: 100%;
  background-color: unset;
  height: 40px;
}

.ahBtn,
.scBtn,
.gdBtn {
  position: relative;
  width: 90%;
  height: 54px;
  background-color: unset;
  border: 0.3px solid #ffffff;
  margin: 10px auto;
}

.select {
  background-color: #f0ab06;
  border: 0px solid #ffffff;
}

.img1 {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 7.5%;
  top: 20px;
  background-image: url(../assets/home_slices/add@2x.png);
  background-size: 100%;
}

.selectImg {
  background-image: url(../assets/home_slices/reduce@2x.png);
  background-position: center;
  background-repeat: no-repeat;
}

.img2 {
  position: absolute;
  width: 15px;
  top: 20px;
  right: 7.5%;
}

.p1 {
  position: absolute;
  top: 14px;
  left: 16%;
  font-size: 12px;
  color: #ffffff;
}

.p2 {
  position: absolute;
  top: 30px;
  left: 16%;
  font-size: 10px;
  color: #ffffff;
}

.ahContent,
.scContent,
.gdContent {
  position: relative;
  margin: 0 auto;
  width: 90%;
  height: 310px;
}

.contentHead {
  position: absolute;
  height: 40px;
  width: 100%;
  display: flex;
}

.contentHead div {
  flex: 1;
  color: #ffffff;
  font-size: 12px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.contentImg {
  position: absolute;
  top: 40px;
  width: 100%;
  height: 92px;
}

.contentP {
  position: absolute;
  top: 155px;
  width: 100%;
  height: 98px;
  color: #d4d2d2;
  font-size: 14px;
}

.moreBtn {
  position: absolute;
  top: 276px;
  right: 22.5px;
  background-color: unset;
  color: #ffffff;
  font-size: 12px;
}

.scene {
  position: relative;
  width: 100%;
  height: 320px;
  background-color: #f0ab06;
}

.scene_titleEnglish {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  color: white;
  font-size: 9px;
}

.scene_title {
  position: absolute;
  top: 52px;
  left: 50%;
  font-size: 15px;
  color: white;
  transform: translate(-50%);
}

.scene_content {
  position: absolute;
  top: 90px;
  left: 5%;
  width: 92%;
  height: 173px;
  display: flex;
  flex-wrap: wrap;
}

.scene_content img {
  margin-right: 1%;
  width: 24%;
  height: 83px;
}

/* 融调宝 */
.rongdiaobao {
  position: relative;
  width: 100%;
  height: 840px;
}

.rongdiaobao_logo {
  position: absolute;
  top: 43px;
  left: 50%;
  width: 22%;
  transform: translate(-50%);
}

.rongdiaobao_p1 {
  position: absolute;
  top: 90px;
  left: 10%;
  width: 80%;
  font-size: 11px;
  text-align: center;
}

.rongdiaobao_p2 {
  position: absolute;
  top: 110px;
  left: 15%;
  width: 70%;
  font-size: 10px;
  transform: scale(0.9);
  text-align: center;
}

.rongdiaobao_content1 {
  position: absolute;
  top: 160px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
}

.rongdiaobao_content1 div {
  margin-left: 6%;
  margin-right: 6%;
  margin-top: 10px;
  width: 21%;
  height: 80px;
}

.rongdiaobao_content1 div img {
  width: 60px;
  height: 60px;
  margin-left: 50%;
  transform: translate(-50%);
}

.rongdiaobao_content1 div p {
  font-size: 11px;
  text-align: center;
}

.rongdiaobao_content2 {
  position: absolute;
  top: 370px;
  left: 3.5%;
  width: 93%;
  height: 250px;
  display: flex;
  flex-wrap: wrap;
}

.rongdiaobao_content2 div {
  position: relative;
  width: 48%;
  height: 125px;
  margin: 0px 1%;
}

.rongdiaobao_content2 .rdb_headDiv {
  position: absolute;
  width: 100%;
  height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: white;
  background-color: #f5be00;
}

.rongdiaobao_content2 .rdb_contentDiv {
  position: absolute;
  left: 5%;
  width: 90%;
  top: 45px;
  font-size: 12px;
}

/* 新闻资讯 */
.news {
  position: relative;
  width: 100%;
  height: 640px;
  background-color: #1e1e1e;
  overflow: hidden;
}

.news_line1 {
  position: absolute;
  top: 73px;
  left: 10%;
  width: 28%;
  background-color: #ffffff;
  height: 0.45px;
}

.news_title {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate(-50%);
  font-size: 17px;
  font-weight: 700;
  color: #fdfdfd;
}

.news_title_son {
  color: #f0ab06;
}

.news_line2 {
  position: absolute;
  top: 73px;
  right: 10%;
  width: 28%;
  background-color: #ffffff;
  height: 0.45px;
}

.news_content {
  margin-top: 120px;
  margin-left: 5%;
  width: 90%;
  height: 0px;
  display: flex;
  flex-wrap: wrap;
}

.news_content_box {
  position: relative;
  margin: 20px 0px;
  height: 80px;
  width: 100%;
}

.news_data {
  position: absolute;
  top: 8.5px;
  left: 0;
  font-size: 20px;
  color: #f0ab06;
  font-weight: 700;
}

.news_years {
  position: absolute;
  top: 40px;
  font-size: 14px;
  color: #cbcbcb;
}

.news_line3 {
  position: absolute;
  background-color: white;
  width: 1px;
  height: 100%;
  left: 22%;
}

.news_contentTitle {
  position: absolute;
  color: white;
  font-size: 15px;
  font-weight: 700;
  top: 8.5px;
  left: 26%;
  right: 0;
  /* 多行显示省略号 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.news_content_content {
  position: absolute;
  color: #bcbcbc;
  font-size: 12px;
  top: 40px;
  left: 26%;
  right: 0;
  /* 多行显示省略号 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
