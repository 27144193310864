// 该文件是专门用于创建整个应用的路由器
// 第一步引入插件(本质是一个构造函数)
import VueRouter from "vue-router";

// 引入用到的组件
// 第二步创建router实例对象并暴露出来
export default new VueRouter ({
    routes: [
        {
            //path是路径(默认路径)
            path:"/",
            //跳转的组件
            component:()=>import('../views/home')
        },
        {
            path:"/changyeyuan",
            component:()=>import('../views/changyeyuan'),
            name:'changyeyuan'
        },
        { 
            path: '/CompanyProfile',
            component:()=>import('../views/CompanyProfile') 
        },
        {
            path:'/cooperation',
            component:()=>import('../views/cooperation')
        },
        {
            path:'/system',
            component:()=>import('../views/system')
        },
        {
            path:'/news',
            component:()=>import('../views/news')
        },
        {
            path:'/about',
            component:()=>import('../views/about')
        },
        {
            path:'/newsDetail',
            component:()=>import('../views/newsDetail'),
            name:'newsDetail'
        },
        {
            path:'/numberDetail',
            component:()=>import('../views/numberDetail')
        },
        {
            path:'/TalentRecruitment',
            component:()=>import('../views/TalentRecruitment')
        }


    ]
})